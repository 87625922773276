import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { VegaFlex } from '@heartlandone/vega-react';
import logo from 'Public/Image/Logo.png';
import notfound from 'Public/Image/notfound.png';
import { copyToClipboard, setFromlocalStorage } from 'Chores/utils';
import { FE_ROUTES } from 'Chores/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const NotFound = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const menus = useSelector((state) => state.common.menus);

  const goBack = () => {
    setFromlocalStorage('loginRedirect', '');
    window.history.back();
  };

  useEffect(() => {
    if (!menus.length) {
      setFromlocalStorage('loginRedirect', location, 20);
      if (keycloak.authenticated) {
        navigate(FE_ROUTES.HOME);
      } else {
        navigate('/');
      }
    }
  }, [menus]);

  return (
    <VegaFlex direction={'col'} gap={'size-24'}>
      <img src={logo} style={{ width: '220px' }}></img>
      <VegaFlex direction={'col'} justifyContent='center' alignItems='center' margin={{ top: 'size-64' }} gap={'size-24'}>
        <img src={notfound}></img>
        <div className='v-font-h4'>Page Not Found</div>
        <div>The page you’re looking for does not exist. Please contact your administrator or email us to <span className='v-text-link cursor-pointer' onClick={() => copyToClipboard('GPDashboardAdministrators@activenetwork.com', 'Email copied to clipboard')}>GPDashboardAdministrators@activenetwork.com</span></div>
        <div className='v-text-link cursor-pointer' onClick={goBack}>Back</div>
      </VegaFlex>
    </VegaFlex>
  );
};

export default NotFound;
